@import "../../_shared/css/preset.css";

/*********************** PCモードサポート ***********************/
body {
    -webkit-text-size-adjust: none;
}

/*********************** 基本フォント ***********************/

/* 見出しはつねにゴシック */
/* ナビゲーションバーはつねにゴシック */
h1, h2, h3, h4, h5, h6 {
    font-family: var(--system-font-family);
}

/*********************** 文字間・行間 ***********************/
body {
    letter-spacing: 0.04em;
    overflow-wrap: break-word;
}
pre, table th, table td {
    overflow-wrap: normal;
}
* {
    line-height: 1.6em;
}
input, button {
    line-height: normal;
}
pre, dl, ol, ul, p, blockquote {
    margin-top: .7em;
    margin-bottom: .7em;
}

/*********************** ほか ***********************/
body {
    background-color: var(--window-background-color);
    color: var(--user-content-text-color);
}

#header {
    background-color: var(--header-background-color);
    border-color: var(--header-border-color);
}

.toolbox-container {
    text-align: left;
    background-color: var(--toolbox-background-color);
    border-width: 1px;
    border-style: solid;
    border-color: var(--toolbox-border-color);
}

.navibar-container {
    color: var(--navibar-text-color);
}

.toolbox-searchbar input {
    padding-top: .25em;
    padding-bottom: .25em;
    margin-top: -2px;
    margin-bottom: -2px;
}

.footer-block {
    padding: 2px 9px;
    font-size: 80%;
    color: var(--footer-text-color);
    background-color: var(--footer-background-color);
    border-style: solid;
    border-width: 1px;
    border-color: var(--footer-border-color);
}

div#body a {
    line-break: strict;
    word-break: break-all;
    word-wrap: break-word;
}

a {
    color: var(--link-text-color);
    background-color: var(--link-background-color);
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-color: var(--link-underline-color);
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;
}

a:visited {
    color: var(--link-text-color);
    background-color: var(--link-background-color);
    text-decoration-color: var(--link-underline-color);
}

a:hover {
    color: var(--link-hover-text-color);
    background-color: var(--link-hover-background-color);
    text-decoration-color: var(--link-hover-underline-color);
    text-decoration-thickness: 2px;
}

h1 {
    color: #c00;
    font-size: 20px;
    background-color: transparent;
    border: 0;
    margin: .2em 0 .2em .5em;
}

h2 {
    color: var(--h2-text-color);
    background-color: var(--h2-background-color);
    margin: .2em 0 .5em;
    padding: .3em .3em .15em .5em;
    border: 0;
    border-left: 15px solid;
    border-bottom: 1px solid;
}

h3 {
    color: var(--h3-text-color);
    background-color: transparent;
    margin: .2em 0 .5em;
    padding: .3em .3em .15em .5em;
    border-left: 15px solid;
    border-bottom: 1px solid;
    border-top: 1px solid;
    border-right: 1px solid;
}

h4, h5, h6 {
    color: var(--h4-text-color);
    background-color: transparent;
    margin: .2em 0 .5em;
    padding: .3em .3em .15em .5em;
}

h1.title {
    font-size: 1.33em;
    font-weight: bold;
    color: var(--title-text-color);
}

div#title {
    margin: 0 0 .5em;
    padding: .5em;
    background-color: var(--title-background-color);
    border: 1px solid var(--title-border-color);
}

/** ヘッダの wiki 名 */
.title2 {
    color: #c00;
    font-size: 19px;
    font-weight: bold;
    border-style: none;
    background-color: transparent;
    padding: 0 3px 2px 0;
    border: 0;
    margin: 0 3px 2px 0;
}

table {
    border-collapse: collapse;
    padding: 0;
    border: 0;
    text-align: left;
}

table thead td, table tfoot td {
    color: var(--table-thead-tfoot-td-text-color);
    background-color: var(--table-thead-tfoot-td-background-color);
}

table thead th, table tfoot th {
    color: var(--table-thead-tfoot-th-text-color);
    background-color: var(--table-thead-tfoot-th-background-color);
}

table th {
    padding: 3px;
    margin: 1px;
    text-align: center;
    color: var(--table-th-text-color);
    background-color: var(--table-th-background-color);
    /*border-right: 0 solid;*/
    /*border-bottom: 0 solid;*/
    border: 1px solid var(--table-th-border-color);
}

table td {
    padding: 3px;
    margin: 1px;
    color: var(--table-td-text-color);
    background-color: var(--table-td-background-color);
    border: 1px solid var(--table-td-border-color);
}

table.conflict_diff > thead th {
    background-color: var(--table-conflict-thead-th-background-color);
    border: 1px solid var(--table-conflict-thead-th-border-color);
}

.c-both {
    clear: both;
}

dt {
    font-weight: bold;
    margin-top: 1em;
    color: var(--dt-text-color);
}

dd {
    margin-left: 1em;
}

pre {
    padding: .5em;
    margin-left: 1em;
    margin-right: 2em;
    line-break: strict;
    word-break: break-all;
    word-wrap: break-word;
    overflow: auto;
    border-top: 1px solid var(--pre-border-top-left);
    border-bottom: 1px solid var(--pre-border-bottom-right);
    border-left: 1px solid var(--pre-border-top-left);
    border-right: 1px solid var(--pre-border-bottom-right);
    color: var(--pre-text-color);
    background-color: var(--pre-background-color);
}

img {
    border: none;
}

em {
    font-style: italic;
}

strong {
    font-weight: bold;
}

ul.list1 {
    list-style-type: disc;
}

ul.list2 {
    list-style-type: circle;
}

ul.list3 {
    list-style-type: square;
}

ol.list1 {
    list-style-type: decimal;
}

ol.list2 {
    list-style-type: lower-roman;
}

ol.list3 {
    list-style-type: lower-alpha;
}

div#body ul.list1,
div#body ol.list1,
div#body dl.list1,
div#body ul.list2,
div#body ol.list2,
div#body dl.list2,
div#body ul.list3,
div#body ol.list3,
div#body dl.list3 {
    margin-left: 16px;
    padding-left: 16px;
}

span.noexists {
    color: inherit;
    background-color: var(--noexists-background-color);
}

.small {
    font-size: 90%;
}

.small1 {
    font-size: 90%;
}

.super_index {
    color: #d33;
    background-color: inherit;
    font-weight: bold;
    font-size: 65%;
    vertical-align: super;
}

a.note_super {
    color: #d33;
    background-color: inherit;
    font-weight: bold;
    font-size: 65%;
    vertical-align: super;
}

hr.note_hr {
    width: 98%;
    text-align: center;
    margin: 0 1%;
}

/* sizex plugin */
.size1 {
    font-size: xx-small;
}
.size2 {
    font-size: x-small;
}
.size3 {
    font-size: small;
}
.size4 {
    font-size: medium;
}
.size5 {
    font-size: large;
}
.size6 {
    font-size: x-large;
}
.size7 {
    font-size: xx-large;
}

.edit_form {
    clear: both;
}

div#menubar {
    word-break: break-all;
    overflow: hidden;
}

div#menubar ul,
div#menubar ol {
    margin: 0 0 0 1em;
    padding: 0 0 0 1em;
}

div#menubar h2 {
    margin: 0 0 0 0;
    padding: 4px;
    text-align: center;
    border-color: var(--menubar-h2-border-color);
    color: var(--menubar-h2-text-color);
    background-color: var(--menubar-h2-background-color);
}

div#menubar h3 {
    margin: 0 0 0 0;
    padding: 4px;
    text-align: center;
    border-color: var(--menubar-h3-border-color);
    color: var(--menubar-h3-text-color);
    background-color: var(--menubar-h3-background-color);
}

div#menubar h4 {
    margin: 0 0 0 0;
    padding: 4px;
    text-align: center;
    border-color: var(--menubar-h4-border-color);
    color: var(--menubar-h4-text-color);
    background-color: var(--menubar-h4-background-color);
}

div#menubar h5 {
    margin: 0 0 0 0;
    padding: 4px;
    text-align: center;
    border-color: var(--menubar-h5-border-color);
    color: var(--menubar-h5-text-color);
    background-color: var(--menubar-h5-background-color);
}

div#sidebar {
    /*width: 160px;*/
    /*padding: 0;*/
    /*margin: 0 2px;*/
    word-break: break-all;
    overflow: hidden;
}

div#sidebar ul {
    margin: 0 0 0 .5em;
    padding: 0 0 0 1em;
}

div#sidebar ul.list1 {
    list-style-type: disc;
}

div#sidebar ul.list2 {
    list-style-type: circle;
}

div#sidebar ul.list3 {
    list-style-type: square;
}

div#sidebar h2 {
    margin: 0 0 4px 0;
    padding: 4px;
    text-align: center;
    border-color: var(--sidebar-h2-border-color);
    color: var(--sidebar-h2-text-color);
    background-color: var(--sidebar-h2-background-color);
}

div#sidebar h3 {
    margin: 0 0 4px 0;
    padding: 4px;
    text-align: center;
    border-color: var(--sidebar-h3-border-color);
    color: var(--sidebar-h3-text-color);
    background-color: var(--sidebar-h3-background-color);
}

div#sidebar h4 {
    margin: 0 0 4px 0;
    padding: 4px;
    text-align: center;
    border-color: var(--sidebar-h4-border-color);
    color: var(--sidebar-h4-text-color);
    background-color: var(--sidebar-h4-background-color);
}

div#sidebar h5 {
    margin: 0 0 4px 0;
    padding: 4px;
    text-align: center;
    border-color: var(--sidebar-h5-border-color);
    color: var(--sidebar-h5-text-color);
    background-color: var(--sidebar-h5-background-color);
}

div#note {
    clear: both;
    padding: 5px;
}

div#attach {
    clear: both;
    padding: 0;
    margin: 0 1%;
    font-size: 86%;
}

div#attach img {
    vertical-align: middle;
}

#topicpathframe {
    text-align: left;
    margin: 0 9px;
    width: 910px;
}

div#topicpath {
    color: var(--topicpath-text-color);
    padding: 0;
    margin: 0.3em 0 0;
}

div#lastmodified {
    color: var(--lastmodified-text-color);
    line-height: 1.2em;
    padding: 0;
    margin: 0 1%;
}

div#related {
    font-size: 80%;
    padding: 0;
    margin: 16px 1% 0 1%;
}

div#preview {
    color: inherit;
}

img#logo {
    float: left;
    margin-right: 20px;
}

.anchor_super {
    font-size: xx-small;
    vertical-align: super;
}

.clear {
    display: block;
    clear: both;
}

div.counter {
    font-size: 70%;
}

span.diff_added {
    background-color: inherit;
    color: var(--diff-added-text-color);
}

span.diff_removed {
    color: red;
    background-color: inherit;
}

hr.short_line {
    text-align: center;
    width: 80%;
}

h5.side_label {
    text-align: center;
}

ul.navi {
    margin: 0;
    padding: 0;
    text-align: center;
}

li.navi_none {
    display: inline;
    float: none;
}

li.navi_left {
    display: inline;
    float: left;
    text-align: left;
}

li.navi_right {
    display: inline;
    float: right;
    text-align: right;
}

span.comment_date {
    font-size: 80%;
}

span.new1 {
    color: #c00;
    background-color: transparent;
    font-weight: bold;
    font-size: xx-small;
}

span.new3 {
    color: orangered;
    background-color: transparent;
    font-weight: bold;
    font-size: xx-small;
}

span.new5 {
    color: var(--new5-text-color);
    background-color: transparent;
    font-weight: bold;
    font-size: xx-small;
}

span.counter {
    font-size: 70%;
}

ul.popular_list {
    padding: 0 0 0 .5em;
    margin: 0 0 0 .5em;
    border: 0;
    word-wrap: break-word;
    word-break: break-all;
}

ul.recent_list {
    padding: 0 0 0 .5em;
    margin: 0 0 0 .5em;
    border: 0;
    word-wrap: break-word;
    word-break: break-all;
}

ul.recent_list li {
}

div.img_margin {
    margin-left: 32px;
    margin-right: 32px;
}
#contents table div.img_margin {
    margin-left: 10%;
    margin-right: 10%;
}

blockquote {
    border: 1px solid #d5d5d5;
    margin-top: .5em;
    margin-bottom: .5em;
}

blockquote p {
    margin: .5em 1em;
}

div.trackback {
    font-size: 80%;
    text-align: right;
}

div.prevnext_l {
    float: left;
}

div.prevnext_r {
    float: right;
}

.dummyblock {
    width: 77%;
    float: left;
    display: block;
}

.center_form {
    text-align: center;
    padding: 4px 0 9px;
    margin: 0;
}

span.add_word {
    background-color: #ff6;
}

span.remove_word {
    background-color: #a0ffff;
}

img.ext {
    margin-left: 2px;
    vertical-align: baseline;
}

img.inn {
    margin-left: 2px;
    vertical-align: baseline;
}

#header table,
#footer table {
    background-color: transparent;
}

#header table th,
#header table td,
#footer table th,
#footer table td {
    margin: 0;
    padding: 0;
    border-color: transparent;
    background-color: transparent;
}

/* ヘッダ/フッタ領域のテーブル、なぜか default には独特な仕様がある */
#header table {
    margin: 0 auto;
}

#footer table {
    width: 98%;
}

#header table td {
    line-height: normal;
}

#header table.vote td,
#header table.vote2 td,
#header table.tvote td,
#footer table.vote td,
#footer table.vote2 td,
#footer table.tvote td
{
}

#footer table.vote {
    width: 0;
}

#header table td h1.title, #footer table td h1.title {
    font-size: 30px;
    font-weight: bold;
    background-color: transparent;
    padding: 0;
    border: 0;
    margin: 0;
}

#header a {
    text-decoration: none;
}

table.attach_table {
    padding: 5px;
    border: 0;
    margin: auto;
    text-align: left;
    color: inherit;
    background-color: var(--plugin-attach-background-color);
}

th.attach_th {
    padding: 2px;
    margin: 1px;
    text-align: center;
    color: inherit;
    background-color: var(--plugin-attach-th-background-color);
}

td.attach_td1 {
    background-color: var(--plugin-attach-td1-background-color);
}

td.attach_td2 {
    background-color: var(--plugin-attach-td2-background-color);
}

#signature a {
    padding: 0 5px;
    text-decoration: none;
    color: var(--signature-link-text-color);
}

#signature a:active {
    padding: 0 5px;
    color: var(--signature-link-text-color);
}

#signature a:visited {
    padding: 0 5px;
    color: var(--signature-link-text-color);
}

#signature a:hover {
    padding: 0 5px;
    color: var(--signature-link-hover-text-color);
}

#body > p, #preview > p {
    word-break: break-all;
}

#body > pre, #preview > pre {
    white-space: pre-wrap;
}

pre > code {
    white-space: pre-wrap;
}

.wiki-source > code {
    font-family: var(--system-font-family);
}
.wiki-source:not(pre) > code {
    white-space: pre-wrap;
}
pre.wiki-source > code {
    white-space: pre;
}

.result-message {
    margin: 0 0 1em;
    padding: 10px 20px;
    background-color: #ffe;
    border: 1px solid #666;
    border-radius: 6px;
    color: #333;
}
.result-message .result-message-icon {
    color: #666;
    font-size: 14px;
}

.warning-message {
    margin: 0 0 1em;
    padding: 10px 20px;
    background-color: #f8d7da;
    border: 1px solid #666;
    border-radius: 6px;
    color: #333;
}
.warning-message .warning-message-icon {
    color: #666;
    font-size: 14px;
}

table.conflict_diff > thead th {
    padding: 1px;
    text-align: left;
}

.heading-after-first {
    margin-top: 8px;
}

input[name=attach_file],
input[name=attach] {
    margin: 8px 0;
}

/*********************** かつての _z.css から吸収したもの ***********************/
th, td {
    font-size: 1em;
}
h2, h3, h4, h5, h6 {
    font-size: 1em;
}
pre {
    font-size: 1em;
}
div#lastmodified {
    font-size: 80%;
}
