ul.diff-log-pagination li {
    background-color: #333;
    border-color: #666;

    & a {
        color: #fff;
    }

    &.diff-log-pagination-active {
        background-color: gray;
        border-color: #666;
    }
}

ul.diff-log-nav li.diff-log-nav-days ul li.active {
    @media (--layout-lg) {
        background-color: black;
        color: #fff;
    }
}
