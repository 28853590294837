/*********************** レイアウト枠 ***********************/

body {
    margin: 0;
    padding: 0 15px;
}

.container-wrapper {
    max-width: 930px; /* wiki 設定で上書きさせる */
    min-width: 930px; /* wiki 設定で上書きさせる */
    margin: 0 auto;
    border: 1px solid var(--container-wrapper-border-color);
    background-color: var(--user-content-background-color);
    text-align: left;
}

.container {
    margin: 9px;
    position: relative;
}

.clearfix::after {
    content: "";
    display: block;
    clear: both;
}

/*********************** 3カラムレイアウト枠 ***********************/

.columns-container {
    display: grid;
    grid-template-rows: 1fr;
    min-height: 381px;
}
.column-left,
.column-center,
.column-right {
    grid-row: 1 / span 1;
    min-width: 0; /* はみ出る対策 */
}

.columns-container:not(.has-left-column):not(.has-right-column) {
    grid-template-columns: 1fr;
}
.columns-container:not(.has-left-column):not(.has-right-column) .column-center {
    grid-column: 1 / span 1;
}

.columns-container.has-left-column:not(.has-right-column) {
    grid-template-columns: 172px 1fr; /* 160 + (5 + 1) * 2 wiki 設定で上書きされる */
}
.columns-container.has-left-column:not(.has-right-column) .column-left {
    grid-column: 1 / span 1;
}
.columns-container.has-left-column:not(.has-right-column) .column-center {
    grid-column: 2 / span 1;
}

.columns-container:not(.has-left-column).has-right-column {
    grid-template-columns: 1fr 160px;
}
.columns-container:not(.has-left-column).has-right-column .column-center {
    grid-column: 1 / span 1;
}
.columns-container:not(.has-left-column).has-right-column .column-right {
    grid-column: 2 / span 1;
}

.columns-container.has-left-column.has-right-column {
    grid-template-columns: 172px 1fr 160px; /* 160 + (5 + 1) * 2 wiki 設定で上書きされる */
}
.columns-container.has-left-column.has-right-column .column-left {
    grid-column: 1 / span 1;
}
.columns-container.has-left-column.has-right-column .column-center {
    grid-column: 2 / span 1;
}
.columns-container.has-left-column.has-right-column .column-right {
    grid-column: 3 / span 1;
}

.columns-container.has-left-column .column-center {
    padding-left: 20px;
}
.columns-container.has-right-column .column-center {
    padding-right: 15px;
}

.container-wrapper {
    min-width: auto;
}

#naviframe .toolbox-mobile {
    display: none;
}
#naviframe .toolbox-desktop {
    display: inline-block;
}

@media (--layout-sm) {
    body {
        padding: 0;
    }
    .container-wrapper {
        max-width: none;
        min-width: 320px;
        border: none;
    }
    .container {
        margin: 0 8px 9px;
    }
    .columns-container {
        display: block;
    }
    .columns-container.has-left-column .column-center,
    .columns-container.has-right-column .column-center {
        padding: 0;
    }
    div.img_margin {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    #header,
    .footer-block {
        margin: 0 -8px;
        padding: 1px 8px;
    }
    #footer .container {
        margin: 0;
        padding: 0 8px;
    }
    #naviframe {
        border: none;
        padding: 4px;
        margin: 0 -8px;
    }
    #naviframe .toolbox-mobile {
        display: inline-block;
    }
    #naviframe .toolbox-desktop {
        display: none;
    }
    .column-right {
        box-sizing: border-box;
        border-style: solid;
        border-width: 1px;
        padding: 5px;
        margin: 9px 0;
        /*background-color: #f5f5f5;*/
    }
}

/*********************** 罫線 ***********************/

.column-left {
    box-sizing: border-box;
    border: 1px solid var(--column-left-border-color);
    padding: 5px;
    background-color: var(--column-left-background-color);
}

.column-right {
    background-color: var(--column-right-background-color);
}

/*********************** コンテンツ内基調整 ***********************/
@custom-selector :--inverse-indented-block #title,h2,h3,h4,h5,h6,.result-message,.warning-message;

#body {
    padding-left: 20px;
    padding-right: 20px;
    overflow-x: visible;
}
#body :--inverse-indented-block {
    margin-left: -19px;
    margin-right: -19px;
}
@media (--layout-sm) {
    /*
     default 系は #body に強いインデントつけて、見出しをマイナスマージンで配置している。
     これは他のスキンと大きく違う点。スマホ幅をめいっぱい使うには向かない。そんなレイアウトは
     リセットして他のスキンに合わせる。
     */
    #body {
        padding-left: 0;
        padding-right: 0;
    }
    #body :--inverse-indented-block {
        margin-left: 0;
        margin-right: 0;
    }
}

#menubar {
}

#sidebar {
    padding: 0 5px;
}

.h-scrollable {
    overflow-x: auto;
    text-align: left;
}

.user-header {
    /*  とくに何もしない */
}
.default-header {
    padding: .5em 0;
    border: 1px solid black;
    background-color: #f5f5f5;
    text-align: right;
    margin-bottom: -1px;
}

.toolbox-container {
    padding: 5px;
}

.toolbox-container .toolbox {
    margin: 2px;
    display: inline-block;
    vertical-align: top;
}

.toolbox-container .toolbox.toolbox-searchbar {
    float: right;
}

.toolbox-container a {
    padding: 1px 5px;
    color: var(--navibar-link-text-color);
    text-decoration: none;
    border: 1px solid transparent;
}

.toolbox-container a:active {
    color: var(--navibar-link-text-color);
}

.toolbox-container a:visited {
    color: var(--navibar-link-text-color);
}

.toolbox-container a:hover {
    color: var(--navibar-link-hover-text-color);
}

@media (--layout-sm) {
    .toolbox-container .toolbox.toolbox-searchbar input[name="word"] {
        width: 84px;
        font-size: 12px;
    }
}

.toolbar img {
    vertical-align: middle;
}

#footer .footer-block-item-left {
    display: inline-block;
}

#footer .footer-block-item-right {
    display: inline-block;
    float: right;
}

#breadcrumbs {
    line-break: strict;
    word-break: break-all;
    word-wrap: break-word;
    float: left;
}

#system-icon-container {
    float: right;
    display: flex;
    align-items: center;
    position: relative;

    // 内部の高さの予測値で固定したい
    min-height: 29px;

    & .single-system-icon {
        display: inline-block;
        padding: 0 8px;
    }

    & .is-frozen-icon {
        border: none;
        color: #888888;
        text-decoration: none;
    }
}
#control-panel {
    padding: 0.1em 0.5em;
    font-size: 11px;
}
#control-panel a {
    border:none;
    color: #888888;
    text-decoration: none;
}
#control-panel a:hover {
    background: transparent;
}

select#report_comment {
    width: 100%;
    height: 2em;
    margin: 0.5em 0;
}
