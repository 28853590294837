@import "_common.css";
@import "_layout.css";
@import "_modules/_index.css";
@import "_modules/dark/_index.css";

:root {
    /* _common */
    --window-background-color: #111;
    --user-content-text-color: #a5a5a5;
    --header-background-color: transparent;
    --header-border-color: black;
    --toolbox-background-color: #171717;
    --toolbox-border-color: #333;
    --navibar-text-color: #171717;
    --footer-text-color: white;
    --footer-background-color: #171717;
    --footer-border-color: #333;
    --link-text-color: #ebebeb;
    --link-background-color: inherit;
    --link-underline-color: #333;
    --link-hover-text-color: #a5a5a5;
    --link-hover-background-color: inherit;
    --link-hover-underline-color: white;
    --h2-text-color: white;
    --h2-background-color: #171717;
    --h3-text-color: white;
    --h4-text-color: white;
    --title-text-color: white;
    --title-background-color: #171717;
    --title-border-color: #333;
    --table-thead-tfoot-td-text-color: inherit;
    --table-thead-tfoot-td-background-color: #171717;
    --table-thead-tfoot-th-text-color: #f5f5f5;
    --table-thead-tfoot-th-background-color: #202020;
    --table-th-text-color: inherit;
    --table-th-background-color: #2d2d2d;
    --table-th-border-color: #4f4f4f;
    --table-td-text-color: inherit;
    --table-td-background-color: black;
    --table-td-border-color: #4f4f4f;
    --table-conflict-thead-th-background-color: #333;
    --table-conflict-thead-th-border-color: #4f4f4f;
    --signature-link-text-color: #f60;
    --signature-link-hover-text-color: #d60;
    --dt-text-color: #ebebeb;
    --pre-background-color: #2d2d2d;
    --pre-border-top-left: #373737;
    --pre-border-bottom-right: #333;
    --pre-text-color: white;
    --noexists-background-color: #333;
    --menubar-h2-border-color: #333;
    --menubar-h2-text-color: white;
    --menubar-h2-background-color: #171717;
    --menubar-h3-border-color: #333;
    --menubar-h3-text-color: white;
    --menubar-h3-background-color: #171717;
    --menubar-h4-border-color: #333;
    --menubar-h4-text-color: white;
    --menubar-h4-background-color: black;
    --menubar-h5-border-color: #333;
    --menubar-h5-text-color: white;
    --menubar-h5-background-color: #171717;
    --sidebar-h2-border-color: #333;
    --sidebar-h2-text-color: white;
    --sidebar-h2-background-color: #171717;
    --sidebar-h3-border-color: #333;
    --sidebar-h3-text-color: white;
    --sidebar-h3-background-color: #171717;
    --sidebar-h4-border-color: #333;
    --sidebar-h4-text-color: white;
    --sidebar-h4-background-color: black;
    --sidebar-h5-border-color: #333;
    --sidebar-h5-text-color: white;
    --sidebar-h5-background-color: #171717;
    --topicpath-text-color: #808080;
    --lastmodified-text-color: #808080;
    --diff-added-text-color: #4e85ea;
    --new5-text-color: #008000;
    --plugin-attach-background-color: #333;
    --plugin-attach-th-background-color: #222;
    --plugin-attach-td1-background-color: black;
    --plugin-attach-td2-background-color: #111;

    /* _layout */
    --user-content-background-color: black;
    --container-wrapper-border-color: #333;
    --navibar-link-text-color: #ccc;
    --navibar-link-hover-text-color: #fff;
    --column-left-border-color: #333;
    --column-left-background-color: black;
    --column-right-background-color: black;

    /* _modules */
    --plugin-ntbr-mask-color: #333;
    --plugin-ntbr-dogear-color: rgba(255,255,255,0.5);
    --plugin-ntbr-background-color: #111;

    --plugin-vote-label-background-color: #333;
    --plugin-vote-td1-background-color: black;
    --plugin-vote-td2-background-color: #171717;

    --plugin-calendar-background-color: #333;
    --plugin-calendar-top-background-color: black;
    --plugin-calendar-today-background-color: #262626;
    --plugin-calendar-saturday-background-color: #040c24;
    --plugin-calendar-sunday-background-color: #280c0e;
    --plugin-calendar-weekday-background-color: #111;
    --plugin-calendar-blank-background-color: black;
    --plugin-calendar-weeklabel-background-color: #202020;

    --plugin-minicalendar-td-border-color: #333;
    --plugin-minicalendar-top-background-color: black;
    --plugin-minicalendar-today-background-color: #262626;
    --plugin-minicalendar-saturday-background-color: #040c24;
    --plugin-minicalendar-sunday-background-color: #280c0e;
    --plugin-minicalendar-weekday-background-color: #111;
    --plugin-minicalendar-blank-background-color: black;
    --plugin-minicalendar-weeklabel-background-color: #202020;
    --plugin-minicalendar-date-weekday-background-color: #333;
}

h2 {
    border-top: 1px solid;
    border-right: 1px solid;
    border-color: #333;
}

h3 {
    border-color: #333;
}

h4, h5, h6 {
    border-left: 15px solid #333;
}

table.conflict_diff > thead th {
    color: inherit;
}

hr {
    border: 0;
    color: #444;
    background-color: #444;
    height: 1px;
}

div#menubar h2 {
    border-width: 3px;
    border-style: double;
}

div#menubar h3 {
    border-width: 1px;
    border-style: solid;
}

div#menubar h4 {
    border-width: 1px;
    border-style: solid;
}

div#menubar h5 {
    border-width: 3px;
    border-style: double;
}

div#sidebar h2 {
    border-width: 2px;
    border-style: double;
}

div#sidebar h3 {
    border-width: 1px;
    border-style: solid;
}

div#sidebar h4 {
    border-width: 1px;
    border-style: solid;
}

div#sidebar h5 {
    border-width: 2px;
    border-style: double;
}

.column-right {
    border-color: #333;
}
