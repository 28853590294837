:root {
    --plugin-ntbr-mask-color: #eee;
    --plugin-ntbr-dogear-color: rgba(0,0,0,0.5);
    --plugin-ntbr-background-color: #fff;
}
.plugin-ntbr:not(.unveiled) {
    background-color: var(--plugin-ntbr-mask-color);
}

div.plugin-ntbr:not(.unveiled),
td.plugin-ntbr:not(.unveiled),
th.plugin-ntbr:not(.unveiled) {
    &::after {
        border-top-color: var(--plugin-ntbr-background-color);
        border-right-color: var(--plugin-ntbr-dogear-color);
        border-bottom-color: var(--plugin-ntbr-background-color);
        border-left-color: var(--plugin-ntbr-background-color);
    }
}
