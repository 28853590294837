table.calendar2_frame {
    margin: 1px;
}

table.minicalendar_frame {
    margin: 0;
}

table.calendar2_frame > tbody > tr > td{
    padding: 1px;
}

table.minicalendar_frame > tbody > tr > td{
    padding: 8px;
}

.style_calendar {
    width: 150px;
    margin: 2px;
    background-color: var(--plugin-calendar-background-color);
}

.style_td_caltop {
    background-color: var(--plugin-calendar-top-background-color);
}

.style_td_today {
    padding: 5px 0;
    background-color: var(--plugin-calendar-today-background-color);
}

.style_td_sat {
    padding: 5px 0;
    background-color: var(--plugin-calendar-saturday-background-color);
}

.style_td_sun {
    padding: 5px 0;
    background-color: var(--plugin-calendar-sunday-background-color);
}

.style_td_blank {
    background-color: var(--plugin-calendar-blank-background-color);
}

.style_td_day {
    background-color: var(--plugin-calendar-weekday-background-color);
}

.style_td_week {
    width: 23px;
    background-color: var(--plugin-calendar-weeklabel-background-color);
}

.ministyle_calendar td {
    border-width: 1px;
    border-style: solid;
    border-color: var(--plugin-minicalendar-td-border-color);
}

.ministyle_td_caltop {
    background-color: var(--plugin-minicalendar-top-background-color);
}

.ministyle_td_today {
    background-color: var(--plugin-minicalendar-today-background-color);
}

.ministyle_td_sat {
    background-color: var(--plugin-minicalendar-saturday-background-color);
}

.ministyle_td_sun {
    background-color: var(--plugin-minicalendar-sunday-background-color);
}

.ministyle_td_blank {
    background-color: var(--plugin-minicalendar-blank-background-color);
}

.ministyle_td_day {
    background-color: var(--plugin-minicalendar-weekday-background-color);
}

.ministyle_td_week {
    background-color: var(--plugin-minicalendar-weeklabel-background-color);
}

.date_weekday .day {
    background-color: var(--plugin-minicalendar-date-weekday-background-color);
}
