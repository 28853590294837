/**
 * PukiWiki Plugin Code highlight
 * dark な skin 用の調整
 */
#contents :not(pre) > code[class*="language-"],
#contents pre[class*="language-"] {
    background-color: var(--pre-background-color);
    border-top: 1px solid var(--pre-border-top-left);
    border-bottom: 1px solid var(--pre-border-bottom-right);
    border-left: 1px solid var(--pre-border-top-left);
    border-right: 1px solid var(--pre-border-bottom-right);
}
