@media (--layout-lg) {
    // default のデスクトップサイズには本文領域にインデントがある。
    // 「全体に左右パディング + インデント不要要素はマイナスマージン」でできていて厄介。

    :root {
        --responsive-layout-item-gap: 20px;
        --responsive-layout-paragraph-indent: 19px;
    }

    // トップレベル
    #content > .responsive-layout-container {
        width: auto;
        margin-left: calc(-1 * var(--responsive-layout-paragraph-indent));
        margin-right: calc(-1 * var(--responsive-layout-paragraph-indent));
    }
}

/*
 default は #body に 20px の padding を取って見出しを
 マイナスマージンで逆に飛び出させている。折りたたみ範囲内は左右に
 20px も余白を取りたくないので、見出しの飛び出しをなくして妥協。
　--inverse-indented-block は assets/skin/default/_layout.css を参照。
*/
#content .responsive-layout-item-content :--inverse-indented-block {
    margin-left: 0;
    margin-right: 0;
}
