.included-page-link-box {
    /* これまでの h1 と同じ見た目 */
    color: #c00;
    font-size: 20px;
    background-color: transparent;
    border: 0;
    margin: .2em 0 .2em .5em;

    /*noinspection CssNoGenericFontName*/
    font-family: var(--system-font-family);

    /* UA の h1 デフォルト */
    font-weight: bold;
}
