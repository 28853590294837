.diff-log-nav {
    margin-left: -19px;
    margin-right: -19px;
}
@media (--layout-sm) {
    .diff-log-nav {
        margin-left: 0;
        margin-right: 0;
    }
}
