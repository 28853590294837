#body .nobr {
    margin: 0 -19px;
    padding: 0 19px;
}
@media (--layout-sm) {
    #body .nobr {
        margin: 0;
        padding: 0;
    }
}
