.wiki-edit-assistant {
    background-color: #333333;
}

.wiki-editor {
    & .react-tabs .react-tabs__tab--selected {
        background-color: #333333;
        color: #eeeeee;
    }

    & .edit_form .wiki-edit-assistant {
        border-color: #666666;
    }
}

.edit-assistant-modal {
    color: #332;

    & h3 {
        color: black;
    }

    &.plugin-modal {
        & dt {
            color: #633;
        }

        & pre {
            border-top: 1px solid #889;
            border-bottom: 1px solid #dde;
            border-left: 1px solid #889;
            border-right: 1px solid #dde;
            color: black;
            background-color: #f0f8ff;
        }
    }
}
