/*
 default は #body に 20px の padding を取って見出しを
 マイナスマージンで逆に飛び出させている。折りたたみ範囲内は左右に
 20px も余白を取りたくないので、見出しの飛び出しをなくして妥協。
　--inverse-indented-block は assets/skin/default/_layout.css を参照。
*/
#body {
    & .fold-container,
    & .lazy-fold-container {
        & .fold-content :--inverse-indented-block {
            margin-left: 0;
        }
    }
}
