.wikiwiki-tablesorter-wrapper table {
    & .header,
    & .tablesorter-header {
        background-image: url(data:image/gif;base64,R0lGODlhFQAJAIAAAP///////yH5BAEAAAEALAAAAAAVAAkAAAIXjI+AywnaYnhUMoqt3gZXPmVg94yJVQAAOw==);
    }

    & thead {
        & .headerSortUp,
        & .tablesorter-headerAsc,
        & .tablesorter-headerSortUp {
            background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAAP///////yH5BAEAAAEALAAAAAAVAAQAAAINjI8Bya2wnINUMopZAQA7);
            border-bottom: #fff 2px solid;
        }

        & .headerSortDown,
        & .tablesorter-headerDesc,
        & .tablesorter-headerSortDown {
            background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAAP///////yH5BAEAAAEALAAAAAAVAAQAAAINjB+gC+jP2ptn0WskLQA7);
            border-bottom: #fff 2px solid;
        }
    }
}
