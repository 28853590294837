/* minicalendar_viewer */
h3.minicalendar {
    margin: 18px -9px 0 -6px;
    border-left: 9px solid;
}

h4.minicalendar {
    margin: 18px -9px 0 -6px;
    border-left: 9px solid;
}

.minicalendar_viewer {
    margin: 0 0 0 12px;
}

.date_weekday .day {
    color: #fff;
}

.date_weekend .day {
    background-color: #14F;
    color: #fff;
}

.date_holiday .day {
    background-color: #F41;
    color: #fff;
}
